import { accountTokens, tokens as tokenImages } from 'config/constants/assets';

const fantomTokens = [
  // erc20 tokens
  {
    address: '0x1539C63037D95f84A5981F96e43850d1451b6216',
    symbol: 'OBOL',
    decimals: 18,
    tokenImage: tokenImages.obol,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    symbol: 'BASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    symbol: 'BSHARE',
    decimals: 18,
    tokenImage: tokenImages.bshard,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0xC078285F16665B3F4bCe74AbDCF0f4C877de3E9f',
    symbol: 'BBOND',
    decimals: 18,
    tokenImage: tokenImages.bbond,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    symbol: 'SMELT',
    decimals: 18,
    tokenImage: tokenImages.smelt,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    symbol: 'USDC',
    decimals: 6,
    tokenImage: tokenImages.usdc,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08',
    symbol: 'GodFootprint',
    decimals: 18,
    tokenImage: accountTokens.godFootprint,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0x4ef20669E38751E4a585638d12dCFc6FF3635Dd1',
    symbol: 'ObolFtmReceipt',
    decimals: 18,
    tokenImage: accountTokens.obolFtmReceipt,
    chainId: 250,
    type: 'ERC20',
  },
  {
    address: '0x2733C1DAa3891E7c9Cdf9bC2aCAD74Aa78578B3b',
    symbol: 'SmeltFtmReceipt',
    decimals: 18,
    tokenImage: accountTokens.smeltFtmReceipt,
    chainId: 250,
    type: 'ERC20',
  },

  // nft
  {
    address: '0x35B228ECb46DBb3AF0bC3A3E0F34D9857dBEc549',
    symbol: 'God nft [Test]',
    decimals: 0,
    tokenImage: accountTokens.godNft,
    chainId: 250,
    type: 'NFT',
  },
  {
    address: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297',
    symbol: 'God nft',
    decimals: 0,
    tokenImage: accountTokens.godNft,
    chainId: 250,
    type: 'NFT',
  },
  {
    address: '0x1C1CDF8928824dac36d84B3486D598B9799bA6c0',
    symbol: 'aBASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 43114,
    type: 'ERC20',
    alwaysShow: true
  },
  {
    address: '0xd44779F0E18729cB6AFB7548cF10EF448a602668',
    symbol: 'pBASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 137,
    type: 'ERC20',
    alwaysShow: true
  },
  {
    address: '0x8a78ec16D4B0d3230441cbB8b43DD18c0b8Dc01c',
    symbol: 'zkBASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 324,
    type: 'ERC20',
    alwaysShow: true
  },
  {
    address: '0x74235c64f806341C14bF2be0887EEcd55daE955E',
    symbol: 'BASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 8453,
    type: 'ERC20',
    alwaysShow: true
  },
  {
    address: '0x9c250b5457BF860caFd1D2E2554eD2f898247E6f',
    symbol: 'OWT',
    decimals: 18,
    tokenImage: tokenImages.staterSmelter,
    chainId: 250,
    type: 'ERC20',
    alwaysShow: true
  },
];

const tokens = [...fantomTokens];

export { tokens };
