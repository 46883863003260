import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import multicall from 'utils/multicall';
import { BigNumber, utils } from 'ethers';
import LpPairAbi from 'config/abi/LpPair.json'
import { getBridgeABI } from 'utils/bridge';
import { getBalanceInEther } from 'utils';
import GodNftAbi from 'config/abi/GodNft.json';

// single
export const fetchUserBridgeData = async (account: string, chainId: string, bridgeInfo: any, tokenFrom: any) => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);

    if( account ){
        if( bridgeInfo && tokenFrom ){
            try{
                const calls1 = [
                    {
                      address: tokenFrom.address,
                      name: 'allowance',
                      params: [account, tokenFrom.contractAddress],
                    },
                    {
                      address: tokenFrom.address,
                      name: 'balanceOf',
                      params: [account],
                    },
                    {
                        address: tokenFrom.feeToken.address,
                        name: 'balanceOf',
                        params: [account],
                    },
                    {
                        address: tokenFrom.feeToken.address,
                        name: 'allowance',
                        params: [account, tokenFrom.contractAddress],
                    },
                  ];

                  const [stakingTokenAllowanceRaw, balanceRaw, balanceFeeRaw, allowanceFee] = await multicall(LpPairAbi, calls1);
                  // console.log(`User balance ${getBalanceInEther(balanceRaw)} Balance Fee ${getBalanceInEther(balanceFeeRaw)} Allowance ${getBalanceInEther(stakingTokenAllowanceRaw)} Allowance Fee ${getBalanceInEther(allowanceFee)}`)
                  return {
                    tokenFromBalance: balanceRaw,
                    tokenFeeBalance: balanceFeeRaw,
                    tokenFromAllowance: stakingTokenAllowanceRaw,
                    tokenFeeAllowance: allowanceFee
                  }
            }
            catch(err){
                console.log(err)
            }
        }
    }
    return undefined;
}
  
// single
export const fetchUserNftBridgeData = async (account: string, chainId: string, bridgeInfo: any, tokenFrom: any) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  if( account ){
      if( bridgeInfo && tokenFrom ){
          try{
              const calls1 = [
                  {
                    address: tokenFrom.address,
                    name: 'balanceOf',
                    params: [account],
                  },
                  {
                      address: tokenFrom.feeToken.address,
                      name: 'balanceOf',
                      params: [account],
                  },
                  {
                      address: tokenFrom.feeToken.address,
                      name: 'allowance',
                      params: [account, tokenFrom.contractAddress],
                  },
                ];

                const [ balanceRaw, balanceFeeRaw, allowanceFee] = await multicall(LpPairAbi, calls1);

                const calls4 = [
                  {
                    address: tokenFrom.address,
                    name: 'isApprovedForAll',
                    params: [account, tokenFrom.contractAddress],
                  }
                ];

                const [stakingTokenAllowanceRaw] = await multicall(GodNftAbi, calls4);
                
                // fetch user nft IDs
                const nftCnt = balanceRaw[0].toNumber();
                const calls2 = Array.from(Array(nftCnt).keys()).map((idx) => ({
                  address: tokenFrom.address,
                  name: 'tokenOfOwnerByIndex',
                  params: [account, idx],
                }));

                const nftIdsRaw = await multicall(GodNftAbi, calls2);
                const nftIds = nftIdsRaw.map((row: any) => row[0].toNumber());

                const calls3 = nftIds.map((nftId: number) => ({
                  nftId,
                  address: tokenFrom.address,
                  name: 'tokenURI',
                  params: [nftId],
                }));

                const tokenUris = await multicall(GodNftAbi, calls3);
                const tokenMetadataArray = await Promise.all(
                  tokenUris.map(async (tokenUri: string) => {
                    const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
        
                    return {
                      image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
                      rank: tokenMetadata.rank || 99999,
                    };
                  })
                );

                const userNfts = nftIds.map((nftId: number, i: number) => ({
                  tokenId: nftId,
                  image: tokenMetadataArray[i].image,
                  rank: tokenMetadataArray[i].rank,
                }));

                // console.log(`User NFT balance ${(balanceRaw)} Balance Fee ${getBalanceInEther(balanceFeeRaw)} Allowance ${(stakingTokenAllowanceRaw)} Allowance Fee ${getBalanceInEther(allowanceFee)}`)
                return {
                  tokenFromBalance: balanceRaw,
                  tokenFeeBalance: balanceFeeRaw,
                  tokenFromAllowance: stakingTokenAllowanceRaw,
                  tokenFeeAllowance: allowanceFee,
                  userNfts
                }
          }
          catch(err){
              console.log(err)
          }
      }
  }
  return undefined;
}

export const fetchUserBridgeData2 = async (account: string, chainId: string, bridgeInfo: any, tokenFrom: any) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);

  if( account ){
      if( bridgeInfo && tokenFrom ){
          try{
              const calls1 = [
                  {
                    address: tokenFrom.address,
                    name: 'allowance',
                    params: [account, tokenFrom.contractAddress],
                  },
                  {
                    address: tokenFrom.address,
                    name: 'balanceOf',
                    params: [account],
                  },
                  {
                      address: tokenFrom.address,
                      name: 'balanceOf',
                      params: [account],
                  },
                  {
                      address: tokenFrom.address,
                      name: 'allowance',
                      params: [account, tokenFrom.contractAddress],
                  },
                ];

                const [stakingTokenAllowanceRaw, balanceRaw, balanceFeeRaw, allowanceFee] = await multicall(LpPairAbi, calls1);
                // console.log(`User balance ${getBalanceInEther(balanceRaw)} Balance Fee ${getBalanceInEther(balanceFeeRaw)} Allowance ${getBalanceInEther(stakingTokenAllowanceRaw)} Allowance Fee ${getBalanceInEther(allowanceFee)}`)
                return {
                  tokenFromBalance: balanceRaw,
                  tokenFeeBalance: balanceFeeRaw,
                  tokenFromAllowance: stakingTokenAllowanceRaw,
                  tokenFeeAllowance: allowanceFee
                }
          }
          catch(err){
              console.log(err)
          }
      }
  }
  return undefined;
}